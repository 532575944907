.contact {
  background: linear-gradient(
      to right,
      rgba(11, 111, 31, 0.8),
      rgba(11, 111, 31, 0.8)
    ),
    url(../../../public/assets/svg/animated.svg);
  background-size: cover;
  background-position: center;
}
.contact__form-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
  padding: 4rem;
  margin-top: 6rem;
  max-width: 80rem;
  text-align: right;
  width: 95%;
  border-radius: 5px;
  margin: 5rem auto 0 auto;
}
@media only screen and (max-width: 37.5em) {
  .contact__form-container {
    padding: 3rem;
  }
}
.contact__form-field {
  margin-bottom: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .contact__form-field {
    margin-bottom: 3rem;
  }
}
.contact__form-label {
  color: #666;
  font-size: 1.4rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}
.contact__form-input {
  color: #333;
  padding: 2rem;
  width: 100%;
  border: 1px solid #ebebeb;
  font-size: 1.6rem;
  letter-spacing: 0px;
  background: #f0f0f0;
  border-radius: 5px;
  font-weight: 600;
  /* Code for Modern Browsers */
  /* Code for WebKit, Blink, Edge */
  /* Code for Internet Explorer 10-11 */
  /* Code for Microsoft Edge */
  /* Code for Mozilla Firefox 4 to 18 */
  /* Code for Mozilla Firefox 19+ */
}
.contact__form-input::placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-webkit-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input:-ms-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-ms-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input:-moz-placeholder {
  opacity: 1;
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-moz-placeholder {
  opacity: 1;
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__btn {
  width: 30%;
  padding: 2rem 4rem;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.contact__form-status {
  color: #0b6f1f;
  font-size: 2rem;
  font-weight: 600;
}
@media only screen and (max-width: 37.5em) {
  .contact__btn {
    width: 100%;
  }
}
