.btn {
  background: #fff;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.btn:hover {
  transform: translateY(-3px);
}
.btn--lg {
  padding: 1.5rem 8rem;
  font-size: 2rem;
}
.btn--md {
  padding: 1.5rem 3rem;
  font-size: 1.6rem;
  width: 23rem;
  text-align: center;
  box-sizing: border-box;
}
.btn--theme {
  background: #0b6f1f;
  color: #fff;
}
.btn--theme-inv {
  color: black;
  background: #fff;
  border: 2px solid #0b6f1f;
  padding: calc(1.5rem - 2px) calc(5rem - 2px);
}
