.home-hero {
  color: #fff;
  background: url(../../../public/assets/svg/animated.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 80rem;
  max-height: 120rem;
  position: relative;
}
@media only screen and (max-width: 37.5em) {
  .home-hero {
    height: unset;
    min-height: unset;
  }
}
.home-hero__socials {
  position: absolute;
  top: 50%;
  border: 2px solid #eee;
  border-left: 2px solid #eee;
  transform: translateY(-50%);
}
@media only screen and (max-width: 56.25em) {
  .home-hero__socials {
    display: none;
  }
}
.home-hero__mouse-scroll-cont {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 37.5em) {
  .home-hero__mouse-scroll-cont {
    display: none;
  }
}
.home-hero__social {
  width: 5rem;
}
.home-hero__social-icon-link {
  width: 100%;
  display: block;
  padding: 1.2rem;
  border-bottom: 2px solid #eee;
  transition: background 0.3s;
}
.home-hero__social-icon-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.home-hero__social-icon-link--bd-none {
  border-bottom: 0;
}
.home-hero__social-icon {
  width: 100%;
}
.home-hero__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90rem;
  width: 92%;
}
@media only screen and (max-width: 37.5em) {
  .home-hero__content {
    padding: 19rem 0 13rem 0;
    margin: auto;
    position: static;
    transform: translate(0, 0);
  }
}
.home-hero__info {
  margin: 3rem auto 0 auto;
  max-width: 80rem;
}
.home-hero__cta {
  margin-top: 5rem;
  text-align: center;
}
