.projects__row {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 5rem;
  margin-bottom: 11rem;
}
@media only screen and (max-width: 56.25em) {
  .projects__row {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-bottom: 8rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .projects__row {
    text-align: center;
  }
}
.projects__row:last-child {
  margin-bottom: 0;
}
.projects__row-img-cont {
  overflow: hidden;
}
.projects__row-img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.projects__row-content {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 56.25em) {
  .projects__row-content {
    align-items: center;
  }
}
.projects__row-content-title {
  font-weight: 700;
  font-size: 2.8rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .projects__row-content-title {
    font-size: 2.4rem;
  }
}
.projects__row-content-desc {
  font-size: 1.8rem;
  color: grey;
  max-width: 60rem;
  line-height: 1.7;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .projects__row-content-desc {
    font-size: 1.7rem;
  }
}
