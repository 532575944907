.not_found {
  color: #fff;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not_found__content {
  color: black;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 5rem;
  flex-wrap: wrap;
}
