.skills {
  display: flex;
  flex-wrap: wrap;
}
.skills__skill {
  display: flex;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.6rem;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 5px;
  font-weight: 600;
  color: #777;
}
.skills__skill:hover {
  transform: scale(1.1);
  background: rgba(153, 153, 153, 0.4);
}

.skills__skill-image {
  height: 21px;
  width: 21px;
}
