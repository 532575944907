/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

Inside media queries 1em is always 16px i.e 1em = 16px at every screen size only inside media queries
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 59%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 56%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 65%;
  }
}

body {
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  font-family: sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

input:focus,
button:focus,
a:focus,
textarea:focus {
  outline: none;
}

button {
  border: none;
  cursor: pointer;
}

textarea {
  resize: none;
}

.heading-primary {
  font-size: 6rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
}
@media only screen and (max-width: 37.5em) {
  .heading-primary {
    font-size: 4.5rem;
  }
}

.heading-sec__mb-bg {
  margin-bottom: 11rem;
}
@media only screen and (max-width: 56.25em) {
  .heading-sec__mb-bg {
    margin-bottom: 8rem;
  }
}

.heading-sec__mb-med {
  margin-bottom: 9rem;
}
@media only screen and (max-width: 56.25em) {
  .heading-sec__mb-med {
    margin-bottom: 8rem;
  }
}

.heading-sec__main {
  display: block;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 3.5rem;
  position: relative;
}
.heading-sec__main--lt {
  color: #fff;
}
.heading-sec__main--lt::after {
  content: "";
  background: #fff !important;
}
.heading-sec__main::after {
  content: "";
  position: absolute;
  top: calc(100% + 1.5rem);
  height: 5px;
  width: 3rem;
  background: #0b6f1f;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}
@media only screen and (max-width: 37.5em) {
  .heading-sec__main::after {
    top: calc(100% + 1.2rem);
  }
}

.heading-sec__sub {
  display: block;
  text-align: center;
  color: #777;
  font-size: 2rem;
  font-weight: 500;
  max-width: 80rem;
  margin: auto;
  line-height: 1.6;
}
@media only screen and (max-width: 37.5em) {
  .heading-sec__sub {
    font-size: 1.8rem;
  }
}
.heading-sec__sub--lt {
  color: #eee;
}

.heading-sm {
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.main-container {
  max-width: 120rem;
  margin: auto;
  width: 92%;
}

.sec-pad {
  padding: 12rem 0;
}
@media only screen and (max-width: 56.25em) {
  .sec-pad {
    padding: 8rem 0;
  }
}

.text-primary {
  color: #fff;
  font-size: 2.2rem;
  text-align: center;
  width: 100%;
  line-height: 1.6;
}
@media only screen and (max-width: 37.5em) {
  .text-primary {
    font-size: 2rem;
  }
}

.d-none {
  display: none;
}

.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #eee;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
}
.mouse::before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eee;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 1.3s infinite;
  -webkit-animation: wheel 1.3s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}
